import React, {useEffect, useState} from "react";
import image from '../../images/how-it-works.jpg';
import MainNoWrap from "../layouts/MainNoWrap";

function Error() {
    return (
        <MainNoWrap>
            <div className="error-page py-4 text-center">
                <img height="100%" src={image} alt="Como funciona o URL Shortener"/>
            </div>
        </MainNoWrap>
    );
}

export default Error;
