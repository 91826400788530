import React from "react";
import Nav from "../Nav";
import "../../styles/Logged.css";

function LoggedLayout({children}) {
    return (
        <main>
            <Nav/>
            {children}
            <footer className="bg-grey text-center text-lg-start">
                <div className="text-center p-3 text-white">
                    BRASA © {new Date().getFullYear()} Copyright
                </div>
            </footer>
        </main>
    );
}

export default LoggedLayout;
