import React, {useEffect, useState} from "react";
import LoggedLayout from "../layouts/LoggedLayout";
import {Line, Pie} from "@reactchartjs/react-chart.js";
import axios from "axios";
import {usePromiseTracker} from "react-promise-tracker";
import {trackPromise} from 'react-promise-tracker';
import Loader from 'react-loader-spinner';
import Chart from "react-apexcharts";
import ExcelFile from "react-export-excel/dist/ExcelPlugin/components/ExcelFile";
import ExcelColumn from "react-export-excel/dist/ExcelPlugin/elements/ExcelColumn";
import ExcelSheet from "react-export-excel/dist/ExcelPlugin/elements/ExcelSheet";
import ReactTooltip from "react-tooltip";

function Estatistica() {
    const [times, setTimes] = useState([]);
    const [links, setLinks] = useState([]);
    const [timeSelected, setTime] = useState("select");
    const [linkSelected, setLink] = useState("select");
    const [activeSelected, setActive] = useState("select");

    const [previewSlug, setPreviewSlug] = useState("");
    const [dataExcel, setDataExcel] = useState([]);
    const [dataDevice, setDataDevice] = useState({});
    const [dataDias, setDataDias] = useState({});
    const [dataDiasAll, setDataDiasAll] = useState({});
    const [totalLinks, setTotalLinks] = useState({});
    const [locations, setLocations] = useState({
        countries: [],
        states: [],
    });
    const [referers, setReferers] = useState([]);

    const beforeTooltip = "Copy to Clipboard";
    const afterTooltip = "Copied!";
    const [tooltip, setTooltip] = useState(beforeTooltip);

    const LoadingIndicator = props => {
        const {promiseInProgress} = usePromiseTracker();

        return (
            promiseInProgress &&
            <div style={{
                width: "100%",
                height: "100",
                display: "flex",
                justifyContent: "center",
                alignItems: "center"
            }}
            >
                <Loader type="ThreeDots" color="#383838" height="100" width="100"/>
            </div>
        );
    }

    function getTipMessage() {
        return tooltip.toString();
    }

    function copyToClipboard() {
        navigator.clipboard.writeText("https://gobrasa.io/" + previewSlug)
        setTooltip(afterTooltip)
    }

    const weekday = [];
    weekday[0] = 'Domingo';
    weekday[1] = 'Segunda-feira';
    weekday[2] = 'Terça-feira';
    weekday[3] = 'Quarta-feira';
    weekday[4] = 'Quinta-feira';
    weekday[5] = 'Sexta-feira';
    weekday[6] = 'Sábado';
    const [heatmap, setHeatmap] = useState({
        options: {
            xaxis: {
                categories: weekday
            }
        },
        series: []

    });

    useEffect(function () {
        axios.get(process.env.REACT_APP_BRASA_API_URL + '/api/url-shortner/get-statistics', {
            params: {}
        })
            .then(res => {
                let myTimes = [];
                for (let row of res.data.links) {
                    if (myTimes.indexOf(row.team) === -1) {
                        myTimes.push(row.team)
                    }
                }
                setTimes(myTimes);
                //refreshData(res.data.statistics)
            })
            .catch(err => {
                console.log(err);
            });
    }, []);

    function handleSelectTipo(newValue) {
        setActive(newValue)

        trackPromise(
            axios.get(process.env.REACT_APP_BRASA_API_URL + '/api/url-shortner/get-statistics', {
                params: {
                    active: newValue,
                    time: timeSelected,
                    id: linkSelected
                }
            })
                .then(res => {
                    let myLinks = [];
                    if (timeSelected !== "select" && timeSelected !== "todos") {
                        for (let row of res.data.links) {
                            myLinks.push({value: row._id, label: row.campaign});
                        }
                    }
                    setLink("select");
                    setLinks(myLinks);
                    refreshData(res.data.statistics)
                })
                .catch(err => {
                    console.log(err);
                }));
    }

    function handleSelectTime(newValue) {
        setTime(newValue);

        trackPromise(
            axios.get(process.env.REACT_APP_BRASA_API_URL + '/api/url-shortner/get-statistics', {
                params: {
                    active: activeSelected,
                    time: newValue,
                }
            })
                .then(res => {
                    let myLinks = [];
                    if (newValue !== "select" && newValue !== "todos") {
                        for (let row of res.data.links) {
                            myLinks.push({value: row._id, label: row.campaign});
                        }
                    }
                    setLink("select");
                    setLinks(myLinks);
                    refreshData(res.data.statistics)
                })
                .catch(err => {
                    console.log(err);
                }));
    }

    function handleSelectLink(newValue) {
        setLink(newValue);
        trackPromise(
            axios.get(process.env.REACT_APP_BRASA_API_URL + '/api/url-shortner/get-statistics', {
                params: {
                    active: activeSelected,
                    time: timeSelected,
                    id: newValue,
                }
            })
                .then(res => {
                    refreshData(res.data.statistics)
                    setPreviewSlug(res.data.links[0].slug)
                })
                .catch(err => {
                    console.log(err);
                }));
    }

    function refreshData(statistics) {
        let myDeviceLabels = [];
        let myDeviceData = [];
        let myDiasLabels = [];
        let myDiasData = [];
        let myCountries = [];
        let myStates = [];
        let myReferers = [];
        let heatmapData = [];
        for (let i = 0; i < 24; i++) {
            heatmapData[i] = [];
            for (let j = 0; j < 7; j++) {
                heatmapData[i][j] = 0;
            }
        }

        setDataExcel(statistics)

        for (let row of statistics) {
            if (myDeviceLabels.indexOf(row.device) === -1) {
                myDeviceLabels.push(row.device)
                myDeviceData.push(1)
            } else {
                myDeviceData[myDeviceLabels.indexOf(row.device)]++;
            }

            const date = new Date(row.date);
            const day = date.getDay();
            const hour = date.getHours();
            heatmapData[hour][day]++;

            const dateString = date.getUTCDate() + "/" + (date.getUTCMonth() + 1).toLocaleString('pt-BR', {
                minimumIntegerDigits: 2,
                useGrouping: false
            });
            if (myDiasLabels.indexOf(dateString) === -1) {
                myDiasLabels.push(dateString)
                myDiasData.push(1)
            } else {
                myDiasData[myDiasLabels.indexOf(dateString)]++;
            }

            let findCountry = myCountries.findIndex((obj => obj.value === row.country));
            if (row.country !== "") {
                if (findCountry === -1) {
                    myCountries.push({value: row.country, label: 1});
                } else {
                    myCountries[findCountry].label++;
                }
            }

            let findState = myStates.findIndex((obj => obj.value === row.state));
            if (row.state !== "") {
                if (findState === -1) {
                    myStates.push({value: row.state, label: 1});
                } else {
                    myStates[findState].label++;
                }
            }

            let filtered_referrer = row.referrer;
            if (filtered_referrer !== null) {
                filtered_referrer = filtered_referrer.replace(/(^\w+:|^)\/\/(www.)*/, '').replace(/\/(\S)*((\?)(\S)*(=)(\S)*)*/, "");
                // Regex to clean protocol, path and params
            }
            let findReferes = myReferers.findIndex((obj => obj.value === filtered_referrer));
            if (findReferes === -1) {
                myReferers.push({value: filtered_referrer, label: 1});
            } else {
                myReferers[findReferes].label++;
            }

        }

        const objHeatmapArray = [];
        let meanHeatmap = 0, maxHeatmap = 0;

        let statisticsLength = statistics.length === 0 ? 1 : statistics.length;
        for (let i in heatmapData) {
            objHeatmapArray[i] = {
                name: i + "h - " + (parseInt(i) + 1) + "h",
                data: []
            };
            for (let j in heatmapData[i]) {
                let value = (Math.floor(100 * (heatmapData[i][j] * 100) / statisticsLength) / 100)
                objHeatmapArray[i].data.push(value);
                meanHeatmap += value;
                maxHeatmap = value > maxHeatmap ? value : maxHeatmap;
            }
        }
        meanHeatmap /= (heatmapData.length * heatmapData[0].length);

        myReferers = myReferers.sort(function (a, b) {
            return b.label - a.label;
        });
        myCountries.sort(function (a, b) {
            return b.label - a.label;
        });
        myStates.sort(function (a, b) {
            return b.label - a.label;
        });

        setReferers(myReferers.slice(0, 5));
        setLocations({
            countries: myCountries.slice(0, 5),
            states: myStates.slice(0, 5),
        });
        let myLastView = "sem resultados";
        if (statistics.length > 0) {
            myLastView = new Date(statistics[statistics.length - 1].date).toLocaleDateString()
        }
        setTotalLinks({
            total: statistics.length,
            last: myLastView
        })
        setDataDevice({
            labels: myDeviceLabels,
            datasets: [{
                data: myDeviceData,
                backgroundColor: [
                    '#548E40',
                    '#FAE108',
                    '#184274',
                    '#1E1E1C',
                    '#1887C9',
                    '#383838',
                ]
            }]
        });
        setDataDias({
            labels: myDiasLabels.slice(Math.max(myDiasLabels.length - 7, 0)),
            datasets: [{
                label: 'Últimos 7 Dias',
                data: myDiasData.slice(Math.max(myDiasData.length - 7, 0)),
                backgroundColor: [
                    '#548E40',
                    '#FAE108',
                    '#1887C9',
                    '#548E40',
                    '#FAE108',
                    '#1887C9',
                    '#548E40',
                    '#FAE108',
                ]
            }]
        });
        setDataDiasAll({
            labels: myDiasLabels,
            datasets: [{
                label: 'Ciclo do Link',
                data: myDiasData,
                backgroundColor: [
                    '#548E40',
                    '#FAE108',
                    '#1887C9',
                    '#548E40',
                    '#FAE108',
                    '#1887C9',
                    '#548E40',
                    '#FAE108',
                ]
            }]
        });
        setHeatmap({
            options: {
                xaxis: {
                    categories: weekday
                },
                plotOptions: {
                    heatmap: {
                        //distributed: true,

                        colorScale: {
                            ranges: [
                                {
                                    from: 0,
                                    to: 0.2,
                                    color: '#184274',
                                    name: 'quase zero',
                                },
                                {
                                    from: 0.2,
                                    to: meanHeatmap,
                                    color: '#F3C515',
                                    name: 'menor que a média',
                                },
                                {
                                    from: meanHeatmap,
                                    to: maxHeatmap,
                                    color: '#548E40',
                                    name: 'maior que a média',
                                }
                            ]
                        }
                    }
                }
            },
            series: objHeatmapArray
        })

    }

    return (
        <LoggedLayout>
            <div className="container main">
                <h1>Estatísticas</h1>
                <div className="row">
                    <div className="col-12 text-sm-right col-md-3">
                        <select className="select-filter-stats" onChange={(e) => handleSelectTipo(e.target.value)}>
                            <option disabled selected value="select">Selecione o Tipo</option>
                            <option key="todos-tipos" value="todos">Todos</option>
                            <option key="activos" value="activos">Ativos</option>
                            <option key="inactivos" value="inactivos">Inativos</option>
                        </select>
                    </div>
                    <div className="col-12 text-sm-right col-md-3 mobile-margin">
                        <select className="select-filter-stats" onChange={(e) => handleSelectTime(e.target.value)}>
                            <option disabled selected value="select">Selecione o Time</option>
                            <option key="todos-times" value="todos">Todos</option>
                            {
                                times.map(
                                    (time) =>
                                        <option key={time} value={time}>{time}</option>
                                )
                            }
                        </select>
                    </div>
                    <div className="col-12 col-md-4">
                        <select disabled={links.length === 0} className="select-filter-stats" onChange={(e) => handleSelectLink(e.target.value)}>
                            <option disabled selected value="select">Selecione a Campanha</option>
                            <option key="todos-links" value="todos">Todos</option>
                            {
                                links.map(
                                    ({value, label}, index) =>
                                        <option key={value} value={value}>{label}</option>
                                )
                            }
                        </select>
                    </div>
                    <div className="col-6 col-md-1 mobile-margin excel-column">
                        <ExcelFile element={
                            <i className="text-green far fa-file-excel middle-transform exporters"/>}>
                            <ExcelSheet data={dataExcel} name="Estatísticas">
                                <ExcelColumn label="Acessado em" value="date"/>
                                <ExcelColumn label="Origem" value={(col) => col.referrer ? col.referrer : ""}/>
                                <ExcelColumn label="IP" value="ip"/>
                                <ExcelColumn label="País" value="country"/>
                                <ExcelColumn label="Estado" value="state"/>
                                <ExcelColumn label="Dispositivo" value="device"/>

                            </ExcelSheet>
                        </ExcelFile>
                    </div>
                    <div className="col-6 col-md-1 mobile-margin excel-column">
                        <i onClick={window.print} className="text-danger far fa-file-pdf middle-transform exporters"/>
                    </div>
                </div>
                <hr/>
                <p>Fuso: GMT+0</p>
                <LoadingIndicator/>
                <div className={"row " + (totalLinks.total === undefined ? "d-none" : totalLinks.total)}>
                    <div className="col-12 text-sm-right col-lg-6 mt-5">
                        <div className="card card-body bg-white">
                            <div className="row">
                                <div className="col-12 col-md-6 text-center">
                                    <p className="desc-stats text-black">Total de Clicks</p>
                                </div>
                                <div className="col-12 col-md-6 text-center">
                                    <p className="desc-stats text-black">{totalLinks.total}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={(linkSelected === "select" || linkSelected === "todos") ? "col-12 text-sm-right col-lg-6 mt-5" : "d-none"}>
                        <div className="card card-body bg-white">
                            <div className="row">
                                <div className="col-12 col-md-6 text-center">
                                    <p className="desc-stats text-black">Último Acesso</p>
                                </div>
                                <div className="col-12 col-md-6 text-center">
                                    <p className="desc-stats text-black">{totalLinks.last}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={(linkSelected !== "select" && linkSelected !== "todos") ? "col-12 text-sm-right col-lg-6 mt-5" : "d-none"}>
                        <div className="card card-body bg-white">
                            <div className="row">
                                <div className="col-10 col-md-10 text-center">
                                    <p title="Clique para copiar!" className="desc-stats text-black linkSelected">https://gobrasa.io/{previewSlug}
                                    </p>
                                </div>
                                <div
                                    onMouseLeave={() => setTooltip(beforeTooltip)}
                                    className="col-2 col-md-2 text-center">
                                    <ReactTooltip
                                        getContent={getTipMessage}
                                        data-delay-update='1000'
                                        place='top'
                                    />
                                    <i
                                        className="fas fa-clipboard desc-stats text-black copy-to-clipboard"
                                        onClick={copyToClipboard}
                                        data-tip={tooltip}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-lg-6 mt-5">
                        <Pie
                            data={dataDevice}
                        />
                    </div>
                    <div className="col-12 col-lg-6 mt-5">
                        <Line
                            data={dataDias}
                        />
                    </div>
                    <div className="col-12 mt-5 force-margin-ciclo">
                        <div className="col-12">
                            <h2>Ciclo do Link</h2>
                            <hr/>
                        </div>
                        <div className="col-12 text-sm-right col-lg-12 mt-5">
                            <Line
                                data={dataDiasAll}
                            />
                        </div>
                    </div>
                    <div className="col-12 mt-5">
                        <h2>Heatmap</h2>
                        <hr/>
                    </div>
                    <div className="col-12 text-sm-right col-lg-12 mt-5">
                        <Chart
                            options={heatmap.options}
                            series={heatmap.series}
                            type="heatmap"
                        />
                    </div>
                    <div className="col-12 mt-5 row force-margin-top5">
                        <div className="col-12">
                            <h2>Top 5</h2>
                            <hr/>
                        </div>
                        <div className="col-12 text-sm-right col-sm-6 col-lg-4 mb-5">
                            <div className="card card-body text-left">
                                <table className="table">
                                    <thead>
                                    <tr>
                                        <th scope="col">País</th>
                                        <th scope="col">#</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {
                                        locations.countries.map(
                                            ({value, label}, index) =>
                                                <tr key={index}>
                                                    <td>{value}</td>
                                                    <td>{label}</td>
                                                </tr>
                                        )
                                    }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div className="col-12 text-sm-right col-sm-6 col-lg-4 mb-5">
                            <div className="card card-body text-left">
                                <table className="table">
                                    <thead>
                                    <tr>
                                        <th scope="col">Região</th>
                                        <th scope="col">#</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {
                                        locations.states.map(
                                            ({value, label}, index) =>
                                                <tr key={index}>
                                                    <td>{value}</td>
                                                    <td>{label}</td>
                                                </tr>
                                        )
                                    }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div className="col-12 text-sm-right col-sm-6 col-lg-4 mb-5">
                            <div className="card card-body text-left">
                                <table className="table referers">
                                    <thead>
                                    <tr>
                                        <th className="col-width-80" scope="col">Origem</th>
                                        <th scope="col">#</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {
                                        referers.map(
                                            ({value, label}, index) =>
                                                <tr key={index}>
                                                    <td>{value === null ? "Acesso Direto" : value}</td>
                                                    <td>{label}</td>
                                                </tr>
                                        )
                                    }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </LoggedLayout>
    );
}

export default Estatistica;
