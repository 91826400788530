import React, { useEffect, useState } from "react";
import { Redirect } from 'react-router-dom';
import MainNoWrap from "../layouts/MainNoWrap";
import GoogleLogin from "react-google-login";
import "../../styles/Login.css";
import GoogleLogo from "../../images/google-logo.png";
import axios from "axios";
import jwt from "jsonwebtoken";

function Login() {
    const [googleStatusError, setGoogleStatusError] = useState(0);
    const [time, setTime] = useState("");

    useEffect(function () {
        localStorage.removeItem(process.env.REACT_APP_JWT_TOKEN);
    }, []);

    function responseGoogle(resp) {

        axios.post(process.env.REACT_APP_BRASA_API_URL + '/api/auth/login', {
            idToken: resp.tokenId
        }).then(res => {
            const { token }  = res.data;

            if (token) {
                const payload = jwt.decode(token);

                localStorage.setItem(process.env.REACT_APP_JWT_TOKEN, token)
                localStorage.setItem(process.env.REACT_APP_MY_USER, JSON.stringify(payload.user));

                setTime(payload.user.Time);
                setGoogleStatusError(1);

            } else {
                setGoogleStatusError(2);
            }
        }).catch(err => {
            console.log(err)
            setGoogleStatusError(2);
        });
    }

    return (
        <MainNoWrap>
            <div className='background-brasa'>
                <div className="box">
                    <div className="text-instruction">
                        <h1>Login</h1>
                        <p>Por favor, entre com sua conta @gobrasa.org.</p>
                    </div>
                    <GoogleLogin
                        clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
                        render={renderProps => (
                            <button onClick={renderProps.onClick} className="google-login"><img src={GoogleLogo} />
                                <span>Login</span></button>
                        )}
                        buttonText="Login"
                        onSuccess={(resp) => {
                            responseGoogle(resp);
                        }}
                        onFailure={(err) => {
                            console.error(err)
                            setGoogleStatusError(2)
                        }}
                        cookiePolicy={'single_host_origin'}
                    />
                    <p style={googleStatusError === 2 ? { display: "block" } : {}} className="error">
                        Erro no login. Tente usar sua conta da BRASA, se o erro persistir contate tech@gobrasa.org.
                    </p>
                </div>
            </div>
            {googleStatusError === 1 ? <Redirect to={time} /> : ""}
        </MainNoWrap>
    );
}

export default Login;
