import React, { useEffect, useState } from "react";
import { Redirect, Route } from "react-router-dom";
import axios from "axios";
import jwt from "jsonwebtoken"

export const ProtectedRoute = ({ component: Component, ...rest }) => {
    const [login, setLogin] = useState(0);

    useEffect(() => {
        axios
            .post(process.env.REACT_APP_BRASA_API_URL + '/api/auth/verify', {}, {
                headers: {
                    'Content-Type': 'application/json',
                    'x-access-token': localStorage.getItem(process.env.REACT_APP_JWT_TOKEN)
                }
            })
            .then(res => {

                if (res.status === 200) {
                    localStorage.setItem(process.env.REACT_APP_MY_USER, JSON.stringify(jwt.decode(localStorage.getItem(process.env.REACT_APP_JWT_TOKEN)).user));
                    setLogin(1);
                
                } else {
                    setLogin(2);
                }
            })
            .catch(err => {
                console.log(err);
                setLogin(2);
            });
    }, []);

    return (
        <Route
            {...rest}
            render={props => {
                if (login === 1) {
                    return <Component {...props} />;
                } else if (login === 2) {
                    return <Redirect to='/' />
                }
            }}
        />
    );
}
