import React, {useEffect, useState} from "react";
import LoggedLayout from "../layouts/LoggedLayout";
import axios from "axios";
import ReactTooltip from "react-tooltip";

function Form({match}) {
    /*
    * 0: Criar
    * 1: Editar
    */
    const [mode, updateMode] = useState(0);
    const [preview, updatePreview] = useState("");
    const [previewInput, updatePreviewInput] = useState("");
    const [campaignName, updateCampaignName] = useState("");
    const [destinyUrl, updateDestinyUrl] = useState("");
    const [expireDate, updateExpireDate] = useState("");
    const [useStatistics, updateUseStatistics] = useState(true);
    const [idLink, updateIdLink] = useState("");
    const [submitMessage, updateSubmitMessage] = useState({status: false, message: ""});

    function handleSubmit(event) {
        event.preventDefault();
        axios.post(process.env.REACT_APP_BRASA_API_URL + '/api/url-shortner/create-url', {
            form: {
                campaign: campaignName,
                slug: previewInput,
                destination: destinyUrl,
                expirationDate: expireDate,
                use_statistics: useStatistics,
                team: user.Time,
                _id: idLink
            },
            headers: {
                'Content-Type': 'application/json',
            }
        })
            .then(res => {
                updateSubmitMessage(res.data);
            })
            .catch(err => {
                console.log(err)
                updateSubmitMessage({
                    status: false,
                    message: "API fora de serviço, por favor tente mais tarde."
                });
            });
    }

    function setPreview(e) {
        updatePreviewInput(e);
        updatePreview(encodeURI(e));
    }

    const beforeTooltip = "Copy to Clipboard";
    const afterTooltip = "Copied!";
    const [tooltip, setTooltip] = useState(beforeTooltip);

    function getTipMessage(){
        return tooltip.toString();
    }

    function copyToClipboard(){
        navigator.clipboard.writeText("https://gobrasa.io/" + preview)
        setTooltip(afterTooltip)
    }

    useEffect(function () {
        if (match.params.id) {
            updateMode(1);
            axios.get(process.env.REACT_APP_BRASA_API_URL + '/api/url-shortner/get-link', {
                params: {
                    link_id: match.params.id
                }
            })
                .then(res => {
                    if (res.data.status === true) {
                        updateCampaignName(res.data.link.campaign)
                        updateDestinyUrl(res.data.link.destination)
                        updateExpireDate(new Date(res.data.link.expirationDate).toISOString().substr(0, 10))
                        updatePreviewInput(res.data.link.slug)
                        updatePreview(res.data.link.slug)
                        updateUseStatistics(res.data.link.use_statistics);
                        updateIdLink(match.params.id);
                    }
                })
                .catch(err => {
                    console.log(err);
                });
        }
    }, []);

    function random(length) {
        let randomChars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        let result = '';
        for (let i = 0; i < length; i++) {
            result += randomChars.charAt(Math.floor(Math.random() * randomChars.length));
        }
        // TODO: Validate on DB -> Unique
        return result;
    }

    const user = JSON.parse(localStorage.getItem(process.env.REACT_APP_MY_USER));
    return (
        <LoggedLayout>
            <div className="container main">
                <h1>{mode === 0 ? "Novo Link" : "Editar Link"}</h1>
                {
                    submitMessage.message !== "" ?
                        <div className={'alert ' + (submitMessage.status === true ? 'alert-success' : 'alert-danger')} role='alert'>{submitMessage.message}</div> : ""
                }
                <form onSubmit={(e) => handleSubmit(e)}>
                    <div className="form-group">
                        <label htmlFor="campaingname">Nome da Campanha</label>
                        <input value={campaignName} onChange={(e) => updateCampaignName(e.target.value)} type="text" className="form-control" id="campaingname" placeholder="Como você quer lembrar desse link? :)"/>
                    </div>
                    <div className="form-group">
                        <label htmlFor="destinationurl">Url de Destino</label>
                        <input value={destinyUrl} onChange={(e) => updateDestinyUrl(e.target.value)} type="text" className="form-control" id="destinationurl" placeholder="Para onde esse link vai?"/>
                    </div>
                    <div className="form-row">
                        <div className="col-10 col-md-11">
                            <label htmlFor="preview">Preview</label>
                            <input disabled type="text" className="form-control p-4" id="preview" value={"https://gobrasa.io/" + preview}/>
                        </div>
                        <div
                            onMouseLeave={() => setTooltip(beforeTooltip)}
                            className="col-2 col-md-1 text-center justify-center">
                            <ReactTooltip
                                getContent={getTipMessage}
                                data-delay-update='1000'
                                place='top'
                            />
                            <i
                                className="fas fa-clipboard desc-stats text-black copy-to-clipboard"
                                onClick={copyToClipboard}
                                data-tip={tooltip}
                            />
                        </div>
                    </div>
                    <div className="form-row">
                        <div className="col-sm-6">
                            <label htmlFor="slug">Slug</label>
                            <div className="input-group">
                                <div className="input-group-prepend">
                                    <div className="input-group-text btn" onClick={() => setPreview(random(Math.floor(Math.random() * 2) + 4))}>
                                        <i className="fas fa-sync-alt"/>
                                    </div>
                                </div>
                                <input onChange={(e) => setPreview(e.target.value)} type="text" className="form-control" id="slug" placeholder="O que vem depois da barra?" value={previewInput}/>
                            </div>
                        </div>
                        <div className="col-sm-3">
                            <label htmlFor="ttl">Expira às 00h de:</label>
                            <input value={expireDate} onChange={(e) => updateExpireDate(e.target.value)} type="date" className="form-control" id="ttl"/>
                        </div>
                        <div className="col-sm-3">
                            <label htmlFor="time">Time</label>
                            <input disabled={true} value={user.Time} type="text" className="form-control" id="time"/>
                        </div>
                    </div>
                    <div className="form-group row">
                        <div className="col-sm-12 text-center my-4 d-flex align-items-center">
                            <input checked={useStatistics} type="checkbox" onChange={() => updateUseStatistics(!useStatistics)} id="useStatistics"/>
                            <label className="mb-1 ml-3" htmlFor="useStatistics">Gostaria de analisar as estatísticas deste link</label>
                        </div>
                    </div>
                    <div className="form-group row">
                        <div className="col-sm-12 text-center my-4">
                            <button type="submit" className="btn btn-primary submit-form">
                                {mode === 0 ? "Gerar" : "Atualizar"} Link
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </LoggedLayout>
    );
}

export default Form;
