import React, {useEffect, useState} from "react";
import MainNoWrap from "../layouts/MainNoWrap";

function Error({match}) {
    const [error, setError] = useState("404");
    const [errorMessage, setErrorMessage] = useState("Desculpe por ter te trazido aqui, pode voltar para página de ");
    useEffect(function () {
        switch (match.params.type) {
            case 'not-autorized':
                setError("403");
                setErrorMessage("Eii, você não devia tentar entrar na página de outros times, volte para ");
        }
    }, []);
    const user = JSON.parse(localStorage.getItem(process.env.REACT_APP_MY_USER));
    return (
        <MainNoWrap>
            <div className="container error-page pt-5">
                <div className="row">
                    <div className="col-12">
                        <h1>Erro {error}</h1>
                        <h2>{errorMessage} <a href={'/'+user.Time}>{user.Time}</a>.</h2>
                    </div>
                </div>
            </div>
        </MainNoWrap>
    );
}

export default Error;
