import React, {useEffect, useState} from "react";
import logo from '../images/brasa.png';
import UserDefaultImg from '../images/default-user-image.png';
import '../styles/Nav.css';

function Nav() {
    const [status, setStatus] = useState(false);
    /*const [userImage, setUserImage] = useState(UserDefaultImg);*/
    /*useEffect(function () {
        if(JSON.parse(localStorage.getItem(process.env.REACT_APP_MY_USER)).imageUrl){
           setUserImage(JSON.parse(localStorage.getItem(process.env.REACT_APP_MY_USER)).imageUrl)
           //setUserTime(JSON.parse(localStorage.getItem(process.env.REACT_APP_MY_USER)).time)
        }
    }, []);*/
    const user = JSON.parse(localStorage.getItem(process.env.REACT_APP_MY_USER));
    return (
        <nav>
            <header className="bg-grey">
                <div className="container">
                    <a href={"/"+user.Time}><img src={logo}/></a>
                    <div id="nav-icon" className={status ? "open" : ""} onClick={() => setStatus(!status)}>
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                    </div>
                </div>
            </header>
            <div id="mainMenu" className={`bg-light-grey align-items-center ${status ? "d-flex" : "d-none"}`}>
                <div className="camada-menu"></div>
                <ul className="mx-auto my-auto text-center">
                    {/*<li><img src={userImage}/></li>*/}
                    <hr/>
                    <li><a href={"/"+user.Time}>Time</a></li>
                    <li><a href="/criar">Criar</a></li>
                    <li><a href="/estatisticas">Estatísticas</a></li>
                    <li><a href="/">Logout</a></li>
                    <hr/>
                </ul>
            </div>
        </nav>
    );
}

export default Nav;
