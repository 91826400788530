import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom"
import HttpsRedirect from 'react-https-redirect';
import { ProtectedRoute } from "./ProtectedRoute";
import Estatistica from "./components/pages/Estatistica";
import Form from "./components/pages/Form";
import Login from "./components/pages/Login";
import Time from "./components/pages/Time";
import Error from "./components/pages/Error";
import HowItWorks from "./components/pages/HowItWorks";

import "./styles/App.css";

function App() {
    return (
        <HttpsRedirect>
            <Router>
                <div>
                    <Switch>
                        <Route path="/" exact component={Login} />
                        <Route path="/como-funciona" component={HowItWorks} />
                        <ProtectedRoute path="/criar" exact component={Form} />
                        <ProtectedRoute path="/estatisticas/" exact component={Estatistica} />
                        <ProtectedRoute path="/editar/:id" exact component={Form} />
                        <ProtectedRoute path="/:time" exact component={Time} />
                        <ProtectedRoute path="/error/:type" component={Error} />
                        <ProtectedRoute component={Error} />
                    </Switch>
                </div>
            </Router>
        </HttpsRedirect>
    );
}

export default App;
