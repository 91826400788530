import React, {useState} from "react";

function MainNoWrap({children}) {
    return (
        <main>
            {children}
        </main>
    );
}

export default MainNoWrap;
