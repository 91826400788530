import React, {useState, useEffect} from "react";
import {Redirect} from 'react-router-dom';
import LoggedLayout from "../layouts/LoggedLayout";
import axios from "axios";

function Time({match}) {
    const [chevronAtivo, setChevronAtivo] = useState(true);
    const [chevronExpirado, setChevronchevronExpirado] = useState(false);

    const [linkAtivos, setLinksAtivos] = useState([]);
    const [linksExpirados, setLinksExpirados] = useState([]);

    const [toBeDeleted, setToBeDeleted] = useState("");

    const [openDeleteModal, setOpenDeleteModal] = useState(false);

    useEffect(() => {
        bringThenAll();
    }, []);

    function bringThenAll() {
        axios.get(process.env.REACT_APP_BRASA_API_URL + '/api/url-shortner/get-actives', {
            params: {
                time: match.params.time
            }
        })
            .then(res => {
                if (res.data.status === true) {
                    setLinksAtivos(res.data.links);
                }
            })
            .catch(err => {
                console.log(err);
            });
        axios.get(process.env.REACT_APP_BRASA_API_URL + '/api/url-shortner/get-old-links', {
            params: {
                time: match.params.time
            }
        })
            .then(res => {
                if (res.data.status === true) {
                    setLinksExpirados(res.data.links);
                }
            })
            .catch(err => {
                console.log(err);
            });
    }

    function deletarLink() {
        setOpenDeleteModal(false);
        axios.delete(process.env.REACT_APP_BRASA_API_URL + '/api/url-shortner/delete-link', {
            data: {
                link_id: toBeDeleted
            }
        })
            .then(res => {
                bringThenAll();
            })
            .catch(err => {
                console.log(err);
            });
    }

    function abrirModalDeletarLink(link_id) {
        setToBeDeleted(link_id);
        setOpenDeleteModal(true);
    }

    const user = JSON.parse(localStorage.getItem(process.env.REACT_APP_MY_USER));
    
    return (
        <LoggedLayout>
            {(user.Time == match.params.time) ? "" : <Redirect to='/error/not-autorized'/>}
            <div className="container main">
                <div className="row">
                    <div className="col-12">
                        <h1>Olá {match.params.time},</h1>
                    </div>
                    <div className="col-12 col-md-6 mb-3">
                        <div className="card">
                            <div className="card-body bg-dark-yellow">
                                <div className="row">
                                    <div className="col-12 col-md-3 text-center">
                                        <div className="number-circle">{linkAtivos.length}</div>
                                    </div>
                                    <div className="col-12 col-md-9 text-center">
                                        <p className="desc-stats">Total de Links Ativos</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-md-6">
                        <a href="/estatisticas/" className="statis-link">Estatísticas<i className="pl-4 fas fa-arrow-right"/></a>
                    </div>
                </div>
                <button className="btn time-collpase-title bg-dark-grey border-0" data-toggle="collapse" href="#multiCollapseExample1" role="button" aria-expanded="false" aria-controls="multiCollapseExample1" onClick={() => setChevronAtivo(!chevronAtivo)}>
                    <span className="text-white d-flex align-items-center">Links Ativos <a href="/criar" className="btn bg-green ml-3 text-white">Novo Link</a></span>
                    <i className={"fas " + (chevronAtivo ? "fa-chevron-up" : "fa-chevron-down")}/></button>
                <div className="collapse show multi-collapse" id="multiCollapseExample1">
                    <div className="card card-body">
                        <table className="table">
                            <thead>
                            <tr>
                                <th scope="col">#</th>
                                <th scope="col">Campanha</th>
                                <th scope="col">Expira Em</th>
                                <th scope="col" className="thin-column" colSpan="2"><i className="fas fa-cog"/></th>
                            </tr>
                            </thead>
                            <tbody>
                            {
                                linkAtivos.map(
                                    (link, index) =>
                                        <tr key={link._id}>

                                            <th scope="row">{index + 1}</th>
                                            <td>{link.campaign}</td>
                                            <td>{new Date(link.expirationDate).toISOString().substr(0,10)}</td>
                                            <td>
                                                <a href={"/editar/" + link._id} className="btn bg-info"><i className="fas fa-pen text-white"/></a>
                                            </td>
                                            <td>
                                                <span onClick={() => abrirModalDeletarLink(link._id)} className="btn bg-danger"><i className="fas fa-trash-alt text-white"/></span>
                                            </td>
                                        </tr>
                                )
                            }

                            </tbody>
                        </table>
                    </div>
                </div>
                <button className="btn time-collpase-title bg-dark-grey border-0 mt-4" type="button" data-toggle="collapse" data-target="#multiCollapseExample2" aria-expanded="false" aria-controls="multiCollapseExample2" onClick={() => setChevronchevronExpirado(!chevronExpirado)}>Links Expirados <i className={"fas " + (chevronExpirado ? "fa-chevron-up" : "fa-chevron-down")}/>
                </button>
                <div className="collapse multi-collapse" id="multiCollapseExample2">
                    <div className="card card-body mb-4">
                        <table className="table">
                            <thead>
                            <tr>
                                <th scope="col">#</th>
                                <th scope="col">Campanha</th>
                                <th scope="col">Expirou Em</th>
                                <th scope="col"/>
                            </tr>
                            </thead>
                            <tbody>
                            {
                                linksExpirados.map(
                                    (link, index) =>
                                        <tr key={link._id}>

                                            <th scope="row">{index + 1}</th>
                                            <td>{link.campaign}</td>
                                            <td>{new Date(link.expirationDate).toISOString().substr(0,10)}</td>
                                            <td>
                                                <a href={"/editar/" + link._id} className="btn bg-info"><i className="fas fa-pen text-white"/></a>
                                            </td>
                                        </tr>
                                )
                            }
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>


            <div id="delete-modal" style={openDeleteModal ? {display: "block"} : {display: "none"}} className="modal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalCenterTitle">Deletar Link</h5>
                            <button onClick={() => setOpenDeleteModal(false)} type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <i className="fas fa-times"/>
                            </button>
                        </div>
                        <div className="modal-body">
                            Confirma que deseja deletar esta campanha?
                        </div>
                        <div className="modal-footer">
                            <button onClick={() => setOpenDeleteModal(false)} type="button" className="btn btn-secondary">Cancelar</button>
                            <button onClick={() => deletarLink()} type="button" className="btn btn-danger">Deletar</button>
                        </div>
                    </div>
                </div>
            </div>
        </LoggedLayout>
    );
}

export default Time;
